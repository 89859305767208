export const HOST = 'najet.ru';

export const PATH = {
    AIRPORTS: 'https://site-api.' +  HOST + '/api/airports-names',
    FLIGHTS: 'https://core.' +  HOST + '/api/cards',
    FLIGHTS_BY_ID: 'https://core.' +  HOST + '/api/requests/',
    CARD_BY_ID: 'https://core.' +  HOST + '/api/cards/',
    EMPTY_LEGS: 'https://core.' +  HOST + '/api/cards/el',
    PICTURES: 'https://media.najet.ru',
    PICTURES_API: 'https://media.najet.ru/api/images/',
    LOGIN_API: 'https://site-api.najet.ru/api/auth/send_login_sms',
    CONFIRM_SMS: 'https://site-api.najet.ru/api/auth/confirm_sms',
    SEARCH_EMAIL: 'https://site-api.najet.ru/api/search-email',
    PARTNER_EMAIL: 'https://site-api.najet.ru/api/partner-email',
    PRE_ORDERS: 'https://site-api.najet.ru/api/pre-orders',
}

export const SETTINGS = {
    FIRST_REQUEST_DATE: { // Начальная конфигурация поиска до указания даты, количество дней от текущего дня и время дня в часах
        DAYS: 1,
        HOURS: 12,
    },
    ORDER_DEPTH_DAYS: 90,  // Количество ячеек с датой от даты ближайшего заказа
}

export const OPERATOR = 'redwings';