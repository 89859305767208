import './Header.scss';

import React, { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

import Menu from "./Menu";

import logo from '../assets/logo.svg';
import menu from '../assets/menu.png';

export default function Header (props)  {

    const [isMenuOpened, setIsMenuOpened] = useState(false);

    let headerClassName;
    if (props.searchPage) { headerClassName = 'searchHeader'; }
    if (props.staticPage) { headerClassName = 'staticHeader'; }

    const Intl = useIntl();

    function changeIsMenuOpened( boolean ){
        if (boolean) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "scroll";
        }
        setIsMenuOpened( boolean );
    }

    return (
        <header className={ headerClassName }>
            <div className="modal-window" style={{ display: isMenuOpened ? 'block' : 'none' }} onClick={ () =>  changeIsMenuOpened(!isMenuOpened) } />
            { isMenuOpened &&
                <Menu
                    changeIsMenuOpened = { changeIsMenuOpened }
                />
            }
            <div className='logo'>
                <div><img className='hamburger_mobile' src={ menu }  alt={ Intl.messages['menu'] } onClick={ () => changeIsMenuOpened(!isMenuOpened) }/></div>
                <div><Link to={ useIntl().messages['currentLocaleLink'] }><img className='logo_icon' src={logo} alt="Najet" /></Link></div>
                {/* { props.searchPage ? <div className="text"><FormattedMessage id='first_service' /></div> : ''} */}
            </div>
            <div className='menu'>
                <span className='hamburger_desktop'><img src={ menu } alt={ Intl.messages['menu'] } onClick={ () => changeIsMenuOpened(!isMenuOpened) } /></span>
                {/* <span className='locale'>
                    <Link to={ useIntl().messages['localeLink'] }>
                        <FormattedMessage id='localeText' />
                    </Link>
                </span> */}
            </div>
        </header>
    );
}
