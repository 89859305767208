import { LOCALES } from './locales'

export const messages = {
    [LOCALES.ENGLISH]: {
        home_title: 'Online booking of business aviation flights',
        home_description: 'Russia’s first online sales Service for business aviation flights',
        contacts: 'Contacts',
        menu: 'Menu',
        main: 'Main',
        localeText: 'RU',
        localeLink: '/',
        currentLocaleLink: '/en/',
        main_label: 'Online sales service for business aviation flights',
        search_flights: 'Search flights',
        from: 'From',
        departure_airport: 'Select departure airport',
        arrival_airport: 'Select arrival airport',
        selectDate: 'Select date and time',
        to: 'To',
        enter_airport_or_city: 'Enter city or airport name',
        when: 'When',
        search: 'Search',
        emptyleg: 'Empty legs',
        emptyleg_description: 'The best offers from airlines that make an empty flight on your itinerary',
        jetsharing: 'Shared charters',
        jetsharing_description: 'Fly in comfort and convenience with other travelers for a fraction of the charter’s cost',
        helicopters: ' Helicopters',
        helicopters_description: 'The most convenient transport, does not require the equipment of a special place for takeoff and landing',
        owner_quote: 'The highest standards of service and full automation: we have no analogues',
        owner_job1: 'Commander of the special flight detachment "Russia" from 2014 to 2017',
        owner_job2: 'President of the United National Business Aviation Association',
        video_header: 'The most aviation office',
        main_benefit: 'We receive data from airlines in real time',
        benefit1_header: 'Time saving',
        benefit2_header: 'Honest prices',
        benefit1_description: 'We show you the information online thanks to a high level of automation. We save your time on calls, correspondence and negotiations with brokers and airlines',
        benefit2_description: 'The prices on our website exclude high commissions of intermediaries. The automatic calculation mechanism guarantees an instant formation of a fair and favorable price',
        request_call: 'Request a call',
        media_about_us: 'Media about us',
        media1_text: 'Russians Explore Business Aviation Growth Possibilities',
        media1_date: '11 November 2018',
        media1_link: 'https://www.ainonline.com/aviation-news/business-aviation/2019-11-11/russians-explore-business-aviation-growth-possibilities',
        media1_logo: 'ainonline',
        media2_date: '31 July 2019',
        media2_link: 'http://journal-download.co.uk/digitalmagazines/BAI/BAI01JUL2019/page_65.html',
        media2_logo: 'business_airport',
        media3_text: 'Najet launches Russian charter platform',
        media3_date: '11 August 2019',
        media3_link: 'https://www.businessairnews.com/mag_story.html?ident=16358',
        media3_logo: 'business_air_news',
        media4_text: 'Najet launches Russian charter platform to provide "real" offers',
        media4_date: '11 September 2019',
        media4_link: 'http://www.charterbroker.aero/viewer/35/html5/index.html?pn=17',
        media4_logo: 'charter_brocker',
        destination: 'Restrictions',
        destination_text: 'We have collected the most important information about sanctions and other restrictions related to the influence of COVID19 in a convenient and understandable form',
        destination_link_text: 'About restrictions',
        how_it_works: 'How it works?',
        how_it_works_text: 'More than 10,000 hours of work of our team members went into the implementation of the software. About 150 presentations and meetings were required to attract partners',
        how_it_works_link_text: 'Project scheme',
        fleet: 'Fleet',
        fleet_text: 'We have compiled a list of partner airlines\' aircraft, according to which you can find out the possibility of performing a flight and its cost online',
        fleet_link_text: 'Our fleet',
        partners: 'Partners',
        amg_name: 'Avia Managament Group',
        amg_text: 'One of the leading business jet operators in Russia. The company is dynamically developing due to following such principles as flight safety, efficiency and high quality standards.',
        bysky_name: 'BySky',
        bysky_text: 'The first Belarusian commercial business aviation operator established in 2017. The company\'s fleet consists of modern single-engine turboprop Pilatus PC-12NG with a capacity of up to 8 passengers.',
        weltall_name: 'Weltall',
        weltall_text: 'The Group of companies has been working for its partners since 2000. The airline\'s fleet consists of Hawker-type aircraft. The highest requirements for safety, cleanliness and order in the cabin are imposed on aircraft.',
        flyfast_name: 'FlyFast',
        flyfast_text: 'The airline has all the necessary certificates issued by the Federal Air Transport Agency for commercial and private air transportation. Flight availability, exclusivity and comfort, privacy and security, individual schedule.',
        s7_name: 'S7 Airlines',
        s7_text: 'S7 Airlines is the largest private airline in Russia with the most modern aircraft fleet in the air transportation market. A wide route network allows our passengers to travel to 181 cities in 26 countries',
        about: 'About',
        about_text: 'Najet is online service for finding and ordering an air charter for passengers and baggage on business aviation aircraft. It is a unique product, implemented for the first time in the world.',
        about_link: 'Team, office, history',
        be_partner: 'Become our partner',
        copyrights: 'LLC "Najet" All rights reserved, ',
        home_last_text_left: 'We use cookies and collect personal data to provide you with the most convenient service, offer the best flight options and improve endlessly. We are obliged to warn you about this so as not to violate the laws of the Russian Federation.',
        home_last_text_right: 'By continuing to use this site, you consent to the use of cookies and the processing of data in accordance with <a href="https://najet.online/docs/rules.pdf">Terms of use of the site</a>, <a href="https://najet.online/docs/politics.pdf">Privacy Policy</a> in relation to personal data and <a href="https://najet.online/docs/offer.pdf">Public offer</a>.',
        first_service:'First online business aviation flight selection service in Russia',
        variants: '{count, plural, one {# result} other {# results}}',
        pax: '{count, plural, one {# passanger} other {# passangers}}',
        pax_short: '{count, plural, one {# pas} other {# pas}}',
        departure_date: 'Departure date',
        departure_time: 'Departure time',
        airportChanged: 'Airport changed',
        timeChanged: 'Time changed',
        timeAirportChanged: 'Changes',
        in: 'in',
        seats: '{count, plural, one {# seat} other {# seats}}',
        there: 'There',
        back: 'Back',
        fly: 'Time in flight',
        hours: '{count, plural, one {# hour} other {# hours}}',
        minutes: '{count, plural, one {# minute} other {# minutes}}',
        specs: 'Technical Specifications',
        specs_short: 'Specifications',
        speed: 'Сruising speed',
        speed_spec: 'km/h',
        range: 'Range',
        range_spec: 'km',
        max_pax: 'Max passangers',
        year_made: 'Year of creation',
        year_overhaul: 'Overhaul',
        year_made_short: 'y.m.',
        order_info_label: 'Order №',
        order_info_text_1: 'The information is up-to-date on ',
        order_info_text_2: '. If necessary, contact the operator by phone: +7(495)120-80-20, name the offer number for quick identification.',
        salon_label: 'Interior',
        salon_rebuild: 'Interior renewal',
        salon_height_text: 'Cabin height',
        salon_length_text: 'Cabin length',
        salon_width_text: 'Cabin width',
        length_text: 'Plane length',
        height_text: 'Plane height',
        wings_text: 'Wing span',
        spec_meters: 'м',
        order: 'Order',
        share: 'Share',
        telephone: 'Telephone',
        telephone_number: '+7 495 120-80-20',
        adress: 'Adress',
        mail: 'Mail',
        mail_adress: 'info@najet.ru',
        adress1: 'Russia, Moscow, Vnukovo,',
        adress2: 'Сentralnaya street, 8Б',
        clients_help: 'Clients helpdesk',
        tech_help: 'Tech helpdesk',
        contacts_title: 'Contacts',
        contacts_description: 'Contacts. Russia’s first online sales Service for business aviation flights. NAJET.',
        search_title: 'Search for flights',
        search_title_destination: 'Flight',
        search_description: 'Search for flights. Russia’s first online sales Service for business aviation flights. NAJET.',
        fleet_title: 'OurFleet',
        fleet_description: 'OurFleet. Russia’s first online sales Service for business aviation flights. NAJET.',
        fleet_header: 'OurFleet',
        sort: 'Sort:',
        sort_low_price: 'Сheap',
        sort_hight_price: 'Expensive',
        sort_pax: 'Lot of seats',
        sort_year: 'New planes',
        price_filter: 'Price up to',
        price_mn: ' mn',
        price_bn: ' bn',
        price_k: ' k',
        class: 'Type',
        currency_in_rub: 'In rubles',
        currency_in_usd: 'In dollars',
        currency_in_eur: 'In euro',
        filters: 'Filters',
        calendar: 'Open calendar',
        name_sort: 'Sort by name',
        year_sort: 'Sort by release date',
        pax_sort: 'Sort by capacity',
        price_sort: 'Sort by price',
        return_flight: 'Return flight',
        favorites: 'Favorites',
        salon: 'Interior scheme',
        play: 'Play',
        rossia: 'Special flight detachment "Russia"',
        close: 'Close',
        send: 'Send',
        contact_manager: 'Special offers at a favorable price are possible along your route, leave a request to our manager',
        form_send: 'Thanks! We will contact you as soon as possible',
        not_found: 'Not found. The route probably falls under <a href="https://najet.online/en/restrictions/">sanctions(more details)</a>. Leave a request, we will try to help',
        enter_telephone: 'Telephone number',
        order_call: 'Order call',
        thanks: 'Thanks, we are already calling...',
        back_to_main_page: 'go back to the main page',
        error_code: '404',
        error_message: 'There is no such page. Try to check the entered address or go back to the ',
        not_found_title: '404 error. Page not found',
        not_found_description: '404 error. Page not found',
        restrictions_title: 'Flight restrictions',
        restrictions_description: 'Flight restrictions',
        restrictions_text:
            '<p><b>Russian airlines and business aviation aircraft are not allowed to fly to EU ' +
            'countries and other countries that have joined the sanctions.</b> Counter measures ' +
            'prohibit flights to Russia by EU airlines</p>\n' +
            '<p><b>The arrival of Russian citizens to the EU on a business jet is prohibited.</b> ' +
            'Russian citizens can travel to the EU only on regular airlines. This does not ' +
            'apply to persons with dual citizenship, but even in this case, each departing ' +
            'person undergoes additional checks and approvals without a guarantee ' +
            'of flight confirmation.</p>',
        about_title: 'About NAJET',
        about_description: 'About NAJET',
        about_content_title: 'About us',
        about_content_text:
            '<p>The company "NAJET" LLC (NAJET) was established at the end of 2017. Shareholders and ' +
            'partners of the company (pioneers of Russian business aviation) have set themselves plans ' +
            'to create a real national air taxi system in Russia. The broadest meaning is put into these words:</p>' +
            '<ul>' +
            '<li>Creation of a software solution for the production management of a commercial airline business aviation/li>' +
            '<li>Creation of solutions for online booking of flights and introduction of new sales technologies for 70% of the Russian market</li>' +
            '<li>Creation of a new segment of low-cost flights for Russia with standard service on light and ultralight jets by attracting appropriate aircraft to the Russian market</li>' +
            '<li>Creation of similar solutions for helicopter air transportation</li>' +
            '</ul>' +
            '<p>The NAJET concept assumes the transfer of the "center of gravity" of the business aviation market ' +
            'from abroad to Russia. The loyalty of the path is already confirmed by events and news.</p>' +
            '<p>As a result of several years of work, we have created unique products for which we received Russian ' +
            'copyright certificates and recommendations for use and implementation from the Ministry of Digital ' +
            'Development, Communications and Mass Communications of the Russian Federation.</p>',
        about_content_team_title: 'Key figures of the company',
        about_content_team_person1_name: 'Mikhail Titov',
        about_content_team_person1_text: 'One of the pioneers of the business aviation industry in Russia. Member The Public Council under the Federal Air Transport Agency. In the past, he was one of the founders and CEO of JSC RusJet Airlines, which is one of the leading business aviation companies in the Russian market. For many years, he headed RusAero, the largest flight support company in Russia.',
        about_content_team_person2_name: 'Julia Reichtman',
        about_content_team_person2_text: 'Head of customer service. More than 15 years of experience in civil aviation. She worked at CBA Vnukovo-3 and for more than 12 years at TSUGA Rusaero JSC. He has extensive experience in communicating with clients, interacting with airlines and organizing business aviation flights.',
        partners_title: 'Become a NAJET partner',
        partners_description: 'Become a NAJET partner',
        partners_content_title: 'Become a partner',
        partners_content_text: '<p>We invite business aviation airlines and brokers to join the najet.online project for cooperation.</p>\n' +
            '<p>Connecting the airline to the project will allow:</p>\n' +
            '<ul>\n' +
            '<li>Increase the productivity of sales departments by automating processes for the formation of proposals</li>\n' +
            '<li>Improve production and commercial performance due to the availability and convenience of receiving offers by users and a corresponding increase in the number of flights</li>\n' +
            '<li>To implement the so-called "passing flights" (empty legs)</li>\n' +
            '</ul>\n' +
            '<p>As a result of cooperation with the project, airlines save time, money and resources.</p>\n' +
            '<p>We also invite brokers to cooperate. We offer various schemes for connecting partner sites to the flight search system, using API or white label solutions.</p>\n' +
            '<p>NAJET LLC has completed the development of a similar platform, but for the sale of helicopter flights. At the moment, the project gathers a pool of helicopter companies with subsequent entry into the market.</p>\n' +
            '<p>In order to become a partner of NAJET, apply for a partnership, we will contact you and answer your questions.</p>',
        partners_content_form_title: 'Feedback for partners',
        partners_content_form_caption: 'Leave your contacts to find out about partnership opportunities with Najet',
        partners_content_form_input_name: 'Name',
        name_example: 'Ivan Ivanov',
        partners_content_form_input_email: 'Email or phone',
        email_example: 'johnsmith@gmail.com',
        partners_content_form_input_message: 'Message or company name',
        message_example: 'Message text',
        partners_content_form_input_submith: 'Submith',
        howitworks_title: 'How it works?',
        howitworks_description: 'We have created the possibility of selecting and booking a private jet in real time at a price known in advance',
        howitworks_slide1_header: 'We have created the possibility of selecting and booking a private jet in real time at a price known in advance',
        howitworks_slide2_header: 'The business aviation industry is closed and opaque in terms of pricing for the end user of the service (purchase through intermediaries-brokers)',
        howitworks_slide2_example: 'An example of how the process of online brokers works',
        howitworks_slide3_header: 'Our service allows you to book a plane in real time at a price known in advance',
        howitworks_slide3_caption: 'How the Najet service works',
        howitworks_slide4_header: 'Advantages of booking flights through our platform',
        howitworks_slide4_caption1: 'Time saving: all calculations online in seconds',
        howitworks_slide4_caption2: 'Reliability: automatic scheduling eliminates human errors',
        howitworks_slide4_caption3: 'Fair price: no hidden markups from brokers',
        book_header: 'Flight booking',
        book_name: 'Name',
        book_email: 'Email',
        book_phone: 'Telephone',
        book_text: 'To the specified e-mail address, after processing the order, you will receive: the aircraft charter agreement, airline rules and other necessary documents. By clicking the "Order" button, you agree to the terms of use of personal data.',
        book_button: 'Book',
        book_confirm: 'Order confirmation',
        book_confirm_text: 'We have sent you a code by SMS. Please enter it to check that the contact melons are correct.',
        book_confirm_code: 'Enter the code from the SMS',
        book_confirm_repeat1: 'The code did not come?',
        book_confirm_repeat2: 'Запросить повторно',
        book_complete: 'Request again',
        book_complete_text: 'We have received your request. Our operator will contact you shortly.',
        soon: 'Soon',
        banner_text: 'Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов. Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов. Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов.'
    },
    [LOCALES.RUSSIAN]: {
        home_title: 'Онлайн-бронирование и продажа рейсов бизнес-авиации',
        home_description: 'Национальный агрегатор бронирования и продаж рейсов бизнес-авиации',
        contacts: 'Контакты',
        menu: 'Меню',
        main: 'Главная',
        localeText: 'EN',
        localeLink: '/en/',
        currentLocaleLink: '/',
        main_label: 'Онлайн-сервис заказа рейсов деловой авиации',
        main_subtitle: 'Реальные предложения <br> <span>МГНОВЕННО!</span> <br> Выбирай, делись, заказывай',
        search_flights: 'Поиск рейсов:',
        from: 'Откуда',
        departure_airport: 'Выбрать аэропорт вылета',
        arrival_airport: 'Выбрать аэропорт прилета',
        selectDate: 'Выбрать дату и время',
        to: 'Куда',
        enter_airport_or_city: 'Введите название города или аэропорт',
        when: 'Когда',
        search: 'Искать',
        emptyleg: 'Попутные рейсы',
        emptyleg_description: 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту.',
        jetsharing: 'По местам',
        jetsharing_description: 'Арендовать кресло в частном самолете гораздо дешевле, чем взять в аренду самолет целиком',
        helicopters: 'Вертолеты',
        helicopters_description: 'Самый удобный вид транспорта, не требует специального оборудования места для взлета-посадки',
        owner_quote: 'Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов',
        owner_job1: 'Командир специального летного отряда "Россия" с 2014 по 2017 год',
        owner_job2: 'Президент Объединенной Национальной Ассоциации Бизнес-авиации',
        video_header: 'Самый авиационный офис',
        main_benefit: 'Мы получаем данные от авиакомпаний в режиме реального времени',
        benefit1_header: 'Экономия времени',
        benefit2_header: 'Честные цены',
        benefit1_description: 'Мы показываем информацию в режиме онлайн брагодаря высокому уровню автоматизации. Экономим ваше время на звонках, переписке и переговорах с брокерами и авиакомпаниями',
        benefit2_description: 'Цены на нашем сайте исключают высокие комиссии посредников. Автоматический механизм расчета гарантирует мгновенное формирование чесной и выгодной цены',
        request_call: 'Заказать звонок',
        media_about_us: 'СМИ о проекте',
        media1_text: 'Эксклюзивное интервью с основателем NAJET',
        media1_date: '29 Марта 2021',
        media1_link: 'https://tv.rbc.ru/archive/ekskluziv/606236762ae596436d4193b5',
        media1_logo: 'rbc',
        media2_date: '18 Декабря 2020',
        media2_link: 'https://umagazine.ru/man/journal/najet/',
        media2_logo: 'instyle',
        media3_text: 'NAJET — Национальный агрегатор авиатакси',
        media3_date: '3 Декабря 2018',
        media3_link: 'https://www.bizavnews.ru/231/20666',
        media3_logo: 'bizavnews',
        media4_text: 'Сооснователь NAJET: «Бизнеc авиация в России все еще близка к 90-м»',
        media4_date: '30 Июня 2021',
        media4_link: 'https://sharespro.ru/content/lyudi/soosnovatel-najet-biznes-aviatsiya-v-rossii-vse-eshche-blizka-k-90-m-41344/',
        media4_logo: 'sharespro',
        destination: 'Санкции и ограничения',
        destination_text: 'Мы собрали самую важную информацию о санкционных и других ограничениях на полеты, связанных с политической ситуацией в мире.',
        destination_link_text: 'Информация об ограничениях',
        how_it_works: 'Как это работает?',
        how_it_works_text: 'Более 10 000 часов работы членов нашей команды ушло на реализацию программного обеспечения. Около 150 презентаций и встреч потребовалось для привлечения партнеров',
        how_it_works_link_text: 'Схема работы проекта',
        fleet: 'Самолеты в системе',
        fleet_text: 'В этом разделе список самолетов авиакомпаний партнеров, по которым можно узнать возможность выполнения рейса и его стоимость в режиме онлайн',
        fleet_link_text: 'Флот проекта',
        partners: 'Партнеры',
        amg_name: 'Авиа Менеджмент Груп',
        amg_text: 'Один из ведущих операторов бизнес-джетов в России. Компания динамически развивается благодаря следованию таким принципам как безопасность полетов, оперативность и высокие стандарты качества.',
        bysky_name: 'BySky',
        bysky_text: 'Первый белорусский коммерческий оператор деловой авиации cозданный в 2017 году. Парк компании состоит из современных турбовинтовых одномоторных Pilatus PC-12NG вместимостью до 8 пассажиров.',
        weltall_name: 'Weltall',
        weltall_text: 'Группа компаний работает для своих партнеров с 2000 года. Флот авиакомпании состоит из самолетов типа Hawker. К самолетам предъявляются высочайшие требования безопасности, соблюдения чистоты и порядка в салоне.',
        tulpar_name: `Авиакомпания Тулпар`,
        tulpar_text: `Это надежность и безопасность пребывания каждого пассажира в небе. Опытная команда, 26 лет работающая с пассажирами первого класса. Профессионализм, подтвержденный многочисленными наградами.`,
        flyfast_name: 'FlyFast',
        flyfast_name: 'Быстролёт',
        flyfast_text: 'Авиакомпания имеет все необходимые сертификаты на выполнение коммерческих и частных авиаперевозок. Рейс по готовности, эксклюзивность и комфорт, конфиденциальность и безопасность, индивидуальное расписание.',
        s7_name: 'S7 Airlines',
        s7_text: 'S7 Airlines - крупнейшая частная авиакомпания России с самым современным парком воздушных судов на рынке авиаперевозок. Широкая маршрутная сеть позволяет нашим пассажирам путешествовать в 181 город в 26 странах мира',
        about: 'О проекте',
        about_text: 'Онлайн-сервис NAJET по выбору и заказу воздушного чартера для перевозки пассажиров и багажа на самолетах бизнес-авиации является уникальным, реализованным впервые в мире, продуктом компании NAJET',
        about_link: 'Команда, офис, история',
        be_partner: 'Стать партнером NAJET',
        copyrights: 'ООО «Наджет» Все права защищены, ',
        home_last_text_left: 'Мы используем файлы cookie и сбор персональных данных, чтобы предоставить вам самый удобный сервис, предлагать лучшие варианты перелета. Продолжая пользоваться этим сайтом, вы соглашаетесь на использование cookie и обработку данных в соответствии с <a href="https://najet.online/docs/rules.pdf" target="_blank">правилами пользования сайта</a> и <a href="https://najet.online/docs/politics.pdf" target="_blank">политикой конфиденциальности</a> в отношении персональных данных.',
        home_last_text_right: 'Ссылки на специализированные страницы нашего сайта по теме: <a href="https://najet.online/arenda-chastnogo-samoleta/">аренда самолета</a>, <a href="https://najet.online/arenda-chastnogo-samoleta/">аренда частного самолета</a>, <a href="https://najet.online/zakaz-samoleta/">заказ самолета</a>, <a href="https://najet.online/zakaz-samoleta/">заказ частного самолета</a>, <a href="https://najet.online/aviabroker/">авиаброкер</a>, <a href="https://najet.online/chasny-perelet/">частный перелет</a>, <a href="https://najet.online/zakaz-chartera/">заказ чартера</a>, <a href="https://najet.online/zakaz-chartera/">аренда чартера</a>, <a href="https://najet.online/arenda-biznes-dzheta/">аренда бизнес джета</a>, <a href="https://najet.online/arenda-biznes-dzheta/">заказ бизнес джета</a>, <a href="https://najet.online/delovaja-biznes-aviacia/">бизнес авиация</a>, <a href="https://najet.online/delovaja-biznes-aviacia/">деловая бизнес авиация</a>, <a href="https://najet.online/vip-charter/">вип чартерные рейсы</a>, <a href="https://najet.online/chastnyj-rejs/">частный рейс</a>, <a href="https://najet.online/private-jet/">private jet</a>.',
        first_service:'Первый в России онлайн-сервис подбора рейсов деловой авиации',
        variants: '{count, plural, one {# вариант} few {# варианта} many {# вариантов} other {# вариантов}}',
        pax: '{count, plural, one {# пассажир} few {# пассажира} many {# пассажиров} other {# пассажиров}}',
        pax_short: '{count, plural, one {# пас} few {# пас} many {# пас} other {# пас}}',
        departure_date: 'Дата вылета',
        departure_time: 'Время вылета',
        airportChanged: 'Другой аэропорт',
        timeChanged: 'Время изменено',
        timeAirportChanged: 'Изменения',
        in: 'в',
        seats: '{count, plural, one {# место} few {# места} many {# мест} other {# мест}}',
        there: 'Туда',
        back: 'Обратно',
        fly: 'Лететь',
        hours: '{count, plural, one {# час} few {# часа} many {# часов} other {# часов}}',
        minutes: '{count, plural, one {# минута} few {# минуты} many {# минут} other {# минут}}',
        specs: 'Технические характеристики',
        specs_short: 'Характеристики',
        speed: 'Крейсерская скорость',
        speed_spec: 'км/ч',
        range: 'Дальность полета',
        range_spec: 'км',
        max_pax: 'Вместимость салона',
        year_made: 'Год выпуска самолета',
        year_made_short: 'г.в.',
        year_overhaul: 'Капремонт',
        order_info_label: 'Предложение: №',
        order_info_text_1: 'Информация является актуальной на ',
        order_info_text_2: '. При необходимости свяжитесь с оператором по телефону: +7(495)120-80-20, назовите номер предложения для быстрой идентификации.',
        salon_label: 'Схема салона',
        salon_rebuild: 'Обновлен салон',
        salon_height_text: 'Высота салона',
        salon_length_text: 'Длинна салона',
        salon_width_text: 'Ширина салона',
        length_text: 'Длинна самолета',
        height_text: 'Высота самолета',
        wings_text: 'Размах крыльев',
        spec_meters: 'м',
        order: 'Отправить заявку',
        share: 'Поделиться',
        telephone: 'Телефон',
        telephone_number: '+7 495 120-80-20',
        adress: 'Адрес',
        mail: 'Почта',
        mail_adress: 'info@najet.ru',
        adress1: 'Москва, Внуково, ул. Центральная,',
        adress2: 'д. 8Б Бизнес центр «Ла-5»',
        clients_help: 'Клиентская поддержка',
        tech_help: 'Техническая поддержка',
        contacts_title: 'Контакты',
        contacts_description: 'Контакты. Национальный агрегатор бронирования и продаж рейсов бизнес-авиации. NAJET.',
        search_title: 'Поиск рейсов',
        search_title_destination: 'Информация о рейсе',
        search_description: 'Поиск рейсов. Национальный агрегатор бронирования и продаж рейсов бизнес-авиации. NAJET.',
        fleet_title: 'Наш флот',
        fleet_description: 'Наш флот. Национальный агрегатор бронирования и продаж рейсов бизнес-авиации. NAJET.',
        fleet_header: 'Флот проекта',
        sort: 'Сортировать:',
        sort_low_price: 'Сначала недорогие',
        sort_hight_price: 'Сначала дорогие',
        sort_pax: 'По количеству мест',
        sort_year: 'По году выпуска',
        price_filter: 'Цена до',
        price_bn: ' млрд',
        price_mn: ' млн',
        price_k: ' тыс',
        class: 'Класс',
        currency_in_rub: 'В рублях',
        currency_in_usd: 'В долларах',
        currency_in_eur: 'В евро',
        filters: 'Фильтры',
        calendar: 'Открыть календарь',
        name_sort: 'Сортировать по названию',
        year_sort: 'Сортировать по дате выпуска',
        pax_sort: 'Сортировать по вместимости',
        price_sort: 'Сортировать по цене',
        return_flight: 'Обратный рейс',
        left: 'Листать налево',
        right: 'Листать направо',
        favorites: 'Избранное',
        salon: 'Схема салона',
        play: 'Начать воспроизведение',
        photo: 'Фотография',
        rossia: 'Летный отряда "Россия"',
        close: 'Закрыть',
        send: 'Отправить',
        contact_manager: 'По вашему маршруту возможны спецпредложения по выгодной цене, оставьте заявку нашему менеджеру',
        form_send: 'Спасибо! Мы свяжемся с вами в ближайшее время',
        not_found: 'Не найдено. Вероятно, маршрут попадает под <a href="https://najet.online/restrictions/">санкции(подробнее)</a>. Оставьте заявку, мы постараемся помочь',
        enter_telephone: 'Номер телефона',
        order_call: 'Заказать звонок',
        thanks: 'Спасибо, уже звоним...',
        back_to_main_page: 'вернуться на главную',
        error_code: '404',
        error_message: 'Такой страницы нет. Попробуйте проверить введенный адрес или ',
        not_found_title: '404-я ошибка. Страница не найдена',
        not_found_description: '404-я ошибка. Страница не найдена',
        restrictions_title: 'Ограничения на полеты',
        restrictions_description: 'Ограничения на полеты',
        restrictions_text: `
            <p>
                <b>Российские авиакомпании и самолеты бизнес-авиации в рамках санкций не имеют права полетов в страны ЕС и другие страны, присоединившиеся к санкциям.</b> Встречными мерами запрещены полеты в Россию авиакомпаний ЕС.
            </p>
            <p>
                <b>Запрещен прилет в ЕС граждан России на бизнес-джете.</b> Граждане РФ могут следовать в ЕС только на регулярных авиалиниях. Это не касается лиц, имеющих двойное гражданство, но даже в этом случае каждый вылетающий проходит дополнительные проверки и согласования без гарантии подтверждения рейса.
            </p>
            <p>
                Сейчас граждане РФ могут могут арендовать бизнес джет для полета напрямую из России в следующие открытые страны:
            </p>
            <p>
                Азербайджан, Алжир, Армения, Афганистан, Бахрейн, Белоруссия, Венесуэла, Египет, Израиль, Индия, Ирак, Иран, Казахстан, Катар, Киргизия, Китай, Куба, Лаос, Мальдивы, Монголия, ОАЭ, Оман, Сейшельские Острова, Сербия, Таджикистан, Таиланд, Тунис, Туркмения, Турция, Узбекистан, Шри-Ланка, Эфиопия
            </p>
            <p>
                Далее из этих стран вы можете продолжить полёт в любые другие страны в том числе страны Европы.
            </p>
        `,
        about_title: 'О проекте NAJET',
        about_description: 'О проекте NAJET',
        about_content_title: 'О проекте',
        about_content_text:
            '<p>Компания ООО «НАДЖЕТ» (NAJET) создана в конце 2017 года. Акционеры и партнёры компании ' +
            '(пионеры российской бизнес авиации) поставили перед собой планы создания реальной национальной ' +
            'системы авиатакси в России. В эти слова вкладывается самый широкий смысл:</p>\n' +
            '<ul>\n' +
            '<li>Создание программного решения для управления производством коммерческой авиакомпании бизнес-авиации</li>\n' +
            '<li>Создание решений для онлайн-бронирования авиарейсов и внедрение новых технологий продаж для 70% рынка России</li>\n' +
            '<li>Создание нового для России сегмента недорогих перелетов со стандартным обслуживанием на лёгких и сверхлегких джетах путем привлечения на рынок России соответствующих ВС</li>\n' +
            '<li>Создание аналогичных решений для вертолётных авиаперевозок</li>\n' +
            '</ul>\n' +
            '<p>Концепция NAJET предполагает перенос «центра тяжести» рынка деловой авиации из-за рубежа в Россию. ' +
            'Верность пути уже подтверждается событиями и новостями.</p>\n' +
            '<p>В результате нескольких лет работы нами были созданы уникальные продукты, на которые ' +
            'были получены российские авторские свидетельства и рекомендации к использованию и внедрению ' +
            'от Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации.</p>',
        about_content_team_title: 'Ключевые фигуры компании',
        about_content_team_person1_name: 'Михаил Титов',
        about_content_team_person1_text: 'Один из пионеров отрасли бизнес авиации в России. Член Общественного совета при Федеральном агентстве воздушного транспорта. В прошлом один из создателей и Генеральный директор ОАО «Авиакомпания «РусДжет», являющейся одной из ведущих компаний бизнес авиации на рынке России. Долгие годы возглавлял крупнейшую в России компанию по обеспечению полетов ОАО «ЦУГА «РусАэро».',
        about_content_team_person2_name: 'Юлия Рейхтман',
        about_content_team_person2_text: 'Руководитель клиентского сервиса. Более 15 лет опыта работы в гражданской авиации. Работала в «ЦБА» Внуково-3 и более 12 лет в АО «ЦУГА» Русаэро». Имеет колоссальный опыт общения с клиентами, взаимодействия с авиакомпаниями и организации рейсов бизнес-авиации.',
        partners_title: 'Стать партнером NAJET',
        partners_description: 'Стать партнером NAJET',
        partners_content_title: 'Стать партнером',
        partners_content_text:
            '<p>Мы приглашаем к сотрудничеству авиакомпании деловой авиации и брокеров подключаться к проекту najet.online.</p>\n' +
            '<p>Подключение авиакомпании к проекту позволит:</p>\n' +
            '<ul>\n' +
            '<li>Увеличить производительность отделов продаж за счет автоматизации процессов по формированию предложений</li>\n' +
            '<li>Улучшить производственные и коммерческие показатели за счет доступности и удобства получения пользователями предложений и соответственного увеличения количества полетов</li>\n' +
            '<li>Реализовывать так называемые «попутные рейсы» (empty legs)</li>\n' +
            '</ul>\n' +
            '<p>В результате сотрудничества с проектом авиакомпании экономят время, деньги и ресурсы.</p>\n' +
            '<p>Мы также приглашаем к сотрудничеству брокеров. Предлагаем различные схемы подключения сайтов партнеров к системе поиска рейсов, с помощью API или white label решения.</p>\n' +
            '<p>Компания ООО «НАДЖЕТ» завершила разработку аналогичной платформы, но для продажи рейсов на вертолетах. В настоящий момент, проект собирает пул вертолетных компаний с последующим выходом на рынок.</p>\n' +
            '<p>Для того, чтобы стать партнером компании NAJET, подайте заявку на партнерство, мы свяжемся с вами и ответим на вопросы.</p>',
        partners_content_form_title: 'Обратная связь для партнеров',
        partners_content_form_caption: 'Оставьте свои контакты, чтобы узнать о возможностях партнерства с Najet',
        partners_content_form_input_name: 'Имя',
        name_example: 'Иван Иванов',
        partners_content_form_input_email: 'Электронная почта или телефон',
        email_example: 'johnsmith@gmail.com',
        partners_content_form_input_message: 'Сообщение или название компании',
        message_example: 'Текст сообщения',
        partners_content_form_input_submith: 'Отправить',
        howitworks_title: 'Как это работает',
        howitworks_description: 'Мы создали возможность подбора и бронирования частного самолета в режиме реального времени по известной заранее цене',
        howitworks_slide1_header: 'Мы создали возможность подбора и бронирования частного самолета в режиме реального времени по известной заранее цене',
        howitworks_slide2_header: 'Отрасль бизнес-авиации является закрытой и непрозрачной с точки зрения ценообразования для конечного пользователя услуги (покупка через посредников-брокеров)',
        howitworks_slide2_example: 'Пример того как устроен процесс работы онлайн брокеров',
        howitworks_slide3_header: 'Наш сервис позволяет бронировать самолет в режиме реального времени по известной заранее цене',
        howitworks_slide3_caption: 'Как работает сервис Najet',
        howitworks_slide4_header: 'Преимущества бронирования перелетов через нашу платформу',
        howitworks_slide4_caption1: 'Экономия времени: все расчеты в режиме онлайн за секунды',
        howitworks_slide4_caption2: 'Надежность: автоматическое планирование исключает человеческие ошибки',
        howitworks_slide4_caption3: 'Честная цена: без скрытых накруток от брокеров',
        book_header: 'Бронирование перелета',
        book_name: 'Как к Вам обращаться?',
        book_email: 'Электронная почта',
        book_phone: 'Номер телефона',
        book_text: 'На указанный адрес электронной почты, вы получите подтверждение получения заявки. После обработки заказа, на этот же адрес Вам будут направлены: договор фрахта воздушного судна, ссылка на оплату или счет и другие необходимые документы.',
        book_button: 'Заказать',
        book_confirm: 'Подтверждение заказа',
        book_confirm_text: 'Мы отправили вам код по СМС. Пожалуйста введите его, чтобы проверить, что контактные дынные верны.',
        book_confirm_code: 'Введите код из СМС',
        book_confirm_repeat1: 'Не пришел код?',
        book_confirm_repeat2: 'Запросить повторно',
        book_complete: 'Запрос отправлен',
        book_complete_text: 'Мы получили ваш запрос. Наш оператор свяжется с вами в ближайшее время.',
        soon: 'Скоро',
        banner_text: 'Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов. Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов. Высочайшие стандарты обслуживания и полная автоматизация: у нас нет аналогов.'
    }
}
